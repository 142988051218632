import { OptionBox } from "./OptionBox";
import { Button } from "./Button";
import { isValidated } from "./helpers";
import size from './size.json';
import { numberFormat } from './numberFormat';
import { useEffect, useState } from "react";
import { find } from 'lodash'


// export function SizeStep(props) {

//     const onChange = (event) => {
//     const target = event.target;
//     props.setSelectedOption([...props.selectedOption, target.value]);
//     console.log("look option", target.value);
// }
export function SizeStep(props) {
  const [optionPrice, setOptionPrice] = useState();

  const onChange = (event) => {
    props.setSelectedOption(event.target.value);
  };

  useEffect(() => {
    props.setPhotos(find(size[props.lookOption].options, { "value": props.selectedOption })["photos"]);
  }, [])

  useEffect(() => {
    props.setPrice(find(size[props.lookOption].options, { "value": props.selectedOption })["total-price-usd"]);
  }, [])

  useEffect(() => {
    // console.log("selectedSize", props.optionState);
  }, [props.previousOption])

  return (
    <>
      <div>
        <h2 className="barlow-semi-condensed-smi-bd uppercase h2 text-3xl md:text-4xl mb-6">
          Choose Your Size
        </h2>
        <div className="space-y-6">
          {size[props.lookOption].options.map((option) => (
            <OptionBox
              key={option.value}
              type={option.type}
              name={option.name}
              float={numberFormat(option["total-price-usd"])}
              value={option.value}
              title={option.title}
              subtitle={option.subtitle}
              selectedOption={props.selectedOption}
              onClick={() => {
                props.setPhotos(option.photos);
                props.setPrice(option["total-price-usd"])
              }}
              onChange={onChange}
            />
          ))}
        </div>
      </div>
      <div>
        {size[props.lookOption]["body-override"]}
      </div>
      <Button
        text="Continue"
        nextStep={() => {
          props.nextStep(isValidated(props.selectedOption));
        }}
      />
    </>
  );
}
