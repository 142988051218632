import { useEffect, useState } from 'react';

export function ProductImageBox(props) {
  
  const [mainPhotoIdx, setmainPhotoIdx] = useState(0);

  function incrementmainPhotoIdx(){
    if(mainPhotoIdx < props.allPhotos.length - 1)
      setmainPhotoIdx(mainPhotoIdx + 1);
  }

  function decrementmainPhotoIdx(){
    if(mainPhotoIdx > 0)
      setmainPhotoIdx(mainPhotoIdx - 1);
  }

  useEffect(() => {
    setmainPhotoIdx(0);
  }, [props.allPhotos]);

  return (
    <div className="md:col-start-1 md:col-end-6 col-span-12 mx-auto select-none">
      <div className="max-w-2xl relative flex flex-col mb-6">
        <div className="flex">
          <div className="mx-auto" style={{maxWidth: "80%"}}>
            <img alt={props.allPhotos[mainPhotoIdx]}  className="object-contain" src={props.allPhotos[mainPhotoIdx]} />
          </div>
          <div onClick={()=>incrementmainPhotoIdx()} className="cursor-pointer w-10 absolute right-0 top-1/2 mr-6 md:-mt-4 -mt-8">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </div>
          <div onClick={()=>decrementmainPhotoIdx()} className="cursor-pointer w-10 absolute left-0 top-1/2 ml-6 md:-mt-4 -mt-8">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
            </svg>
          </div>
        </div>
        <div className="mt-3 flex flex-row space-x-3 justify-center">
          {
            (props.allPhotos).map((photoUrl, photoIdx)=> <div onClick={()=>setmainPhotoIdx(photoIdx)} className="w-10">
                  <img alt={`thumbnail-${photoIdx}`} className="w-full" src={photoUrl} />
                </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
