import {isValidated} from './helpers';

export function Button(props) {
  return (
    <button onClick={() => props.nextStep()} className="
          bg-blue-501 
          font-white 
          text-white 
          py-3 
          px-12">
      <span className="mr-2">{props.text}</span>
      <div className="
              w-4 
              inline-block 
              -mb-0.5">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
        </svg></div>
    </button>
  );
}
