import "./App.css";
import { useEffect, useState } from "react";
import { Navigation } from "./Navigation";
import { BuildSummaryStep } from "./BuildSummaryStep";
import { FuelStep } from "./FuelStep";
import { StyleStep } from "./StyleStep";
import { SizeStep } from "./SizeStep";
import { LookStep } from "./LookStep";
import { ProductImageBox } from "./ProductImageBox";
import stepsConfig from "./steps-config.json";
import contentConfig from "./content-config.json";
import skuMap from "./sku-map.json";
import { numberFormat } from './numberFormat';
import style from './style.json';

function App() {
  const [step, setStep] = useState(0);
  let titles = [];
  Object.keys(stepsConfig).forEach((step) => {
    titles.push(stepsConfig[step].nav);
  });

  // Options : muscle-grill or encore-grill
  const [lookOption, setLookOption] = useState("MUS");
  const [styleOption, setStyleOption] = useState("");
  const [sizeOption, setSizeOption] = useState("36");
  const [fuelOption, setFuelOption] = useState("NG");

  // This is meant to get info from model info from query string to determine initial state
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const queryParams = new URLSearchParams(location.search)
    const model = queryParams.get("model")
    if (typeof style[model] !== 'undefined') {
      setLookOption(model);
    }
  }, []);

  const optionState = () => {
    return {
      lookOption, styleOption, sizeOption, fuelOption
    }
  }

  const [price, setPrice] = useState(0);

  const [photos, setPhotos] = useState([
    "https://picsum.photos/id/330/1065/677",
    "https://picsum.photos/id/600/1065/677",
    "https://picsum.photos/id/545/1065/677",
  ]);

  function addToPrice(newAddend) {
    setPrice(price + newAddend);
  }

  function concatBuiltSKU() {
    const validateForCompleteness =
      (lookOption && styleOption) ||
      (styleOption == "" && sizeOption && fuelOption);
    return validateForCompleteness
      ? `${lookOption}${styleOption}${sizeOption}-${fuelOption}`
      : undefined;
  }

  function getStorefrontId() {
    return skuMap[concatBuiltSKU()]["id"];
  }

  // console.log("concat", concatBuiltSKU());

  function setStepByName(step_name) {
    setStep(titles.indexOf(step_name));
  }

  function nextStep(isValidated) {
    // console.log("length of titles", titles.length);
    if (step < titles.length - 1) if (isValidated) setStep(step + 1);
  }



  return (
    <div className="min-h-full barlow-rg text-gray-500 subpixel-antialiased	">
      <div className="flex w-full flex-col">
        <header className="w-full shadow-md p-3 sticky top-0 bg-white z-50">
          <img className="mx-auto my-3" src="logo.png" alt="logo" />
        </header>
        <main className="mt-12 w-full grid grid-flow-row">
          <Navigation currentStep={step} setStep={setStepByName} titles={titles} />
          <section className="md:grid md:grid-flow-col grid-flow-row mt-12 px-6">
            <ProductImageBox allPhotos={photos} />
            <div className="col-start-7 col-end-8 space-y-6 max-w-sm md:w-96 mx-auto">
              {
                [
                  <LookStep
                    body={contentConfig.default.body}
                    selectedOption={lookOption}
                    setSelectedOption={setLookOption}
                    setSizeOption={setSizeOption}
                    nextStep={nextStep}
                    setPrice={setPrice}
                    price={price}
                    setPhotos={setPhotos}
                  />,
                  <SizeStep
                    lookOption={lookOption}
                    selectedOption={sizeOption}
                    setSelectedOption={setSizeOption}
                    nextStep={nextStep}
                    price={price}
                    setPrice={setPrice}
                    setPhotos={setPhotos}
                  />,
                  <StyleStep
                    lookOption={lookOption}
                    sizeOption={sizeOption}
                    selectedOption={styleOption}
                    setSelectedOption={setStyleOption}
                    nextStep={nextStep}
                    setPhotos={setPhotos}
                    price={price}
                    setPrice={setPrice}
                  />,
                  <FuelStep
                    selectedOption={fuelOption}
                    setSelectedOption={setFuelOption}
                    nextStep={nextStep}
                    price={price}
                    setPrice={setPrice}
                  />,
                  <BuildSummaryStep lookOption={lookOption} price={price} setPrice={setPrice} variantId={getStorefrontId} nextStep={nextStep} builtSku={() => concatBuiltSKU()} />,
                ][step]
              }
            </div>
            <div className="col-start-9 col-end-12"></div>
          </section>
        </main>

        <div className="h-16"></div>
        <footer className="md:items-center fixed h-16 bottom-0 grid grid-flow-col bg-tan-101 w-full p-3">
          <div className="inline-block col-start-1 col-start-2 md:col-start-2 md:col-end-5">
            <span className="uppercase barlow-bd">YOUR {{ "MUS": "Muscle", "EST": "Estate", "ENC": "Encore" }[lookOption]} Grill </span>
            <span className="uppercase barlow-bd">({sizeOption}" Grill {{ "": "Built-in", "FS": "Freestanding" }[styleOption]} {{ "NG": "Natural Gas", "LP": "Propane" }[fuelOption]})</span>
          </div>
          <div className="inline-block col-start-3 col-end-7 md:col-start-6 md:col-end-9">
            {!!price &&
              <>
                <span className="">Price:</span>
                <span className="barlow-bd text-blue-501">{price ? numberFormat(price) : ''}</span>
              </>
            }
          </div>
          <div className="inline-block col-start-8 col-end-12 md:col-start-10">
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
